import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Puff } from 'react-loader-spinner';
import useBookStore from '../../store';
import { apiRequest } from '../../axiosHelper';
import { errorToast } from '../../toast';

const BookDetails = () => {

    const { wishlist, cart, addToCart, addToWishlist, removeFromWishlist } = useBookStore()

    const { id } = useParams();
    const [book, setBook] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [jwt, setJwt] = useState(null)
    const navigate = useNavigate();

    const cartBook = cart.filter((e) => e.book.id.toString() === id)

    useEffect(() => {

        setJwt(localStorage.getItem('jwt'));

        const fetchBook = async () => {
            try {
                const response = await apiRequest('get', `/booklist/${id}`)
                setBook(response)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        fetchBook()
    }, [id])

    if (loading) {
        return (
            <div className='h-screen flex items-center justify-center'>
                <Puff
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        )
    }

    if (error) {
        return <div>Error loading book details: {error.message}</div>
    }

    const isInWishlist = wishlist.some(item => item.id === book.id);

    const authenticatedButton = async (type, id) => {
        if (!jwt) {
            navigate('/login')
            errorToast(`Please login to add to ${type}`)
            return
        } else {
            if (type === 'cart') {
                addToCart(id)
            } else if (type === 'wishlist') {
                addToWishlist(id)
            }
        }
    }

    return (
        <div className='flex flex-col lg:flex-row items-center mt-7 gap-4 px-4'>
            <div className='flex flex-col gap-3 w-2/5'>
                <img className='w-full h-[70vh] object-contain' src={book.cover} alt={book.title}></img>
            </div>
            <div className='flex flex-col pb-4 pr-4 gap-3 w-3/5'>
                <div className='font-semibold underline'>{book.title}</div>
                <div className='flex gap-2 items-center'>
                    <div>by</div>
                    {book.authors.map(author => (
                        <div key={author} className='border w-fit px-3 py-1 rounded-full bg-gray-300'>{author}</div>
                    ))}
                </div>
                <div>Price: ${book.price}</div>
                <div className='font-semibold underline'>Description: </div>
                <div className='text-justify lg:w-[60ch]'>{book.description}</div>
                <div className='flex flex-col lg:flex-row gap-3 w-fit'>
                    {isInWishlist ? (
                        <button onClick={() => removeFromWishlist(book.id)} className='border hover:scale-105 duration-300 transition-all p-2 rounded-lg bg-red-300 border-black'>Remove from Wishlist</button>
                    ) : (
                        <button onClick={() => authenticatedButton('wishlist', book.id)} className='border hover:scale-105 duration-300 transition-all p-2 rounded-lg bg-yellow-300 border-black'>Add to Wishlist</button>
                    )}
                    <button onClick={() => authenticatedButton('cart', book.id)} className='border hover:scale-105 duration-300 transition-all p-2 rounded-lg bg-yellow-300 border-black'>Add to Cart</button>
                </div>
                {cartBook.length > 0 ? (
                    <div className='italic'>(in cart)</div>
                ) : (
                    <div className='italic'>(Not in cart)</div>
                )}
            </div>
        </div>
    )
}

export default BookDetails