import React from 'react'
import { mdiOwl, mdiDelete } from '@mdi/js'
import Icon from '@mdi/react'
import useBookStore from '../store'
import AuthWrapper from './AuthWrapper'

const Cart = () => {

    const { cart, wishlist, increaseQuantity, decreaseQuantity, addToWishlist, removeFromCart } = useBookStore();

    const totalAmount = cart.reduce((total, book) => total + book.book.price * book.quantity, 0) || 0;
    const totalQuantity = cart.reduce((total, book) => total + book.quantity, 0) || 0;

    const isBookInWishList = (id) => wishlist.some(book => book.id === id)

    if (cart.length === 0) {
        return <div className='h-[85vh] w-full flex flex-col gap-2 items-center justify-center'>
            <Icon path={mdiOwl} size={2} />
            <div>It's empty here, Browse the booklist to add books to cart!</div>
        </div>
    } else {
        return (
            <div className='px-4'>
                <div className='mt-5 items-center flex flex-col gap-5'>
                    <h2 className='text-2xl underline'>Cart Summary</h2>
                    <div>Total items in cart: {totalQuantity}</div>
                    <div>Cart Total: ${totalAmount.toFixed(2)}</div>
                </div>
                <hr className='my-5' />
                {cart.map((book) => (
                    <div key={book.book.id} className="flex border py-5 flex-col items-center lg:flex-row">
                        <div className='flex flex-col gap-3 w-2/5'>
                            <img className='p-1 w-full h-[70vh] object-contain' src={book.book.cover} alt={book.book.title}></img>
                        </div>
                        <div className='flex flex-col pr-4 gap-3 w-3/5'>
                            <div className='font-semibold underline'>{book.book.title}</div>
                            <div className='flex gap-2 items-center'>
                                <div>by</div>
                                {book.book.authors.map(author => (
                                    <div key={author} className='border w-fit px-3 py-1 rounded-full bg-gray-300'>{author}</div>
                                ))}
                            </div>
                            <div>Price: ${book.book.price}</div>
                            <div className='font-semibold underline'>Description: </div>
                            <div className='text-justify lg:w-[60ch]'>{book.book.description}</div>
                            <div className='flex gap-4 border w-fit border-black p-1'>
                                <button onClick={() => decreaseQuantity(book.book.id)} className='px-3 hover:scale-105 duration-300 transition-all bg-red-500'>-</button>
                                <div>{book.quantity}</div>
                                <button onClick={() => increaseQuantity(book.book.id)} className='px-3 hover:scale-105 duration-300 transition-all bg-green-500'>+</button>
                            </div>
                            <div className='mt-3 flex gap-5 items-center'>
                                {isBookInWishList(book.book.id) ? (
                                    <div className='italic'>(In wishlist)</div>
                                ) : (
                                    <button className='bg-yellow-400 px-4 py-1 rounded' onClick={() => {
                                        addToWishlist(book.book.id)
                                        removeFromCart(book.book.id)
                                    }}>Move to Wishlist</button>
                                )}
                                <div onClick={() => removeFromCart(book.book.id)}>
                                    <Icon className='hover:scale-105 duration-300 transition cursor-pointer' path={mdiDelete} size={1} color='red' />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

}

export default AuthWrapper(Cart)