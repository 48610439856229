import React from 'react'
import Icon from '@mdi/react'
import { mdiOwl } from '@mdi/js'
import { NavLink } from 'react-router-dom'
import useBookStore from '../store'
import AuthWrapper from './AuthWrapper'

const WishList = () => {

    const { wishlist, removeFromWishlist, removeFromCart, cart, addToCart } = useBookStore()

    if (wishlist.length === 0) {
        return <div className='h-[85vh] w-full flex flex-col gap-2 items-center justify-center'>
            <Icon path={mdiOwl} size={2} />
            <div>It's empty here, Browse the booklist to add books to wishlist!</div>
        </div>
    }

    const isBookInCart = (id) => cart.some(book => book.book.id === id)

    return (
        <div className='mt-5 px-4 flex flex-col gap-4'>
            <h2 className='text-3xl font-semibold text-center'>Wishlist</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                {wishlist.map((book) => (
                    <div key={book.id} className='relative'>
                        <div className='flex gap-2 h-72 text-xs flex-col items-center p-4 border shadow-md cursor-pointer bg-white justify-center'>
                            <NavLink to={`/booklist/${book.id.toString()}`} key={book.id}>
                                <div className='flex flex-col items-center gap-1'>
                                    <img src={book.cover} alt={book.title} className='border border-gray-800 w-24 h-36 object-cover' />
                                    <div className='font-semibold'>{book.title}</div>
                                    <div>by {book.authors[0]}</div>
                                </div>
                                <div>
                                    {isBookInCart(book.id) && (
                                        <div className='italic text-center mt-1'>(In cart)</div>
                                    )}
                                </div>
                            </NavLink>
                            {isBookInCart(book.id) && (
                                <button onClick={() => removeFromCart(book.id)} className='px-4 py-2 bg-yellow-400 rounded-lg hover:scale-105 duration-300 transition-all'>Remove from cart</button>
                            )}
                            {!isBookInCart(book.id) && (
                                <button onClick={() => {
                                    addToCart(book.id)
                                    removeFromWishlist(book.id)
                                }} className='px-4 py-2 bg-yellow-400 rounded-lg hover:scale-105 duration-300 transition-all'>Move to cart</button>
                            )}
                        </div>
                        <button onClick={() => removeFromWishlist(book.id)} className='absolute top-2 right-4 px-2 bg-red-500 text-white rounded-lg'>
                            X
                        </button>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default AuthWrapper(WishList)