import React, { useEffect, useState } from 'react'
import useBookStore from '../store'
import Icon from '@mdi/react';
import { mdiCheck, mdiClose, mdiPencil } from '@mdi/js';
import { apiRequest } from '../axiosHelper';
import { errorToast, successToast } from '../toast';
import AuthWrapper from './AuthWrapper';
import { useNavigate } from 'react-router-dom';

const Profile = () => {

    const { user, fetchLoggedInUser } = useBookStore();
    const [editName, setEditName] = useState(false)
    const [editNameValue, setEditNameValue] = useState('')
    const [editEmail, setEditEmail] = useState(false)
    const [editEmailValue, setEditEmailValue] = useState('')
    const [editPassword, setEditPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        const fetchUser = async () => {
            await fetchLoggedInUser()
        }
        fetchUser()
        setEditEmailValue(user?.email)
        setEditNameValue(user?.name)
    }, [fetchLoggedInUser, user?.email, user?.name])

    const updateEmail = async (email) => {
        if (email.length === 0) {
            errorToast('Email cannot be empty.')
        } else {
            try {
                await apiRequest('patch', '/user/update', { email });
                setEditEmail(false)
                successToast('Email updated successfully')
            } catch (error) {
                errorToast(`Could not update email: ${error.response.data.message || error.message || error.response?.data || error}`)
            }
        }
    }

    const updateName = async (name) => {
        if (name.length === 0) {
            errorToast('Email cannot be empty.')
        } else {
            try {
                await apiRequest('patch', '/user/update', { name });
                setEditName(false)
                successToast('Name updated successfully')
            } catch (error) {
                errorToast(`Could not update name: ${error.response.data.message || error.message || error.response?.data || error}`)
            }
        }
    }

    const updatePassword = async ({ password, newPassword, confirmPassword }) => {
        if (newPassword.length < 5) {
            errorToast('New password should be atleast 5 characters long')
        }
        if (password === confirmPassword) {
            errorToast('New password cannot be the same as your old password')
        } else if (newPassword !== confirmPassword) {
            errorToast('Passwords do not match')
        } else {
            try {
                await apiRequest('patch', '/user/update', {
                    oldPassword: password,
                    newPassword: confirmPassword
                });
                setEditPassword(false)
                setPassword('')
                setNewPassword('')
                setConfirmPassword('')
                successToast('Password updated successfully')
            } catch (error) {
                errorToast(`Could not update password: ${error.response.data.message || error.message || error.response?.data || error}`)
            }
        }
    }

    const logoutUser = () => {
        localStorage.removeItem('jwt');
        navigate('/login')
    }

    return (
        <div className='p-8 flex flex-col gap-5'>
            <div className='flex items-center gap-5'>
                <div className='flex flex-col items-center gap-1'>
                    <img className='w-16 rounded-full border-2 border-dashed border-black' src='/assets/emmett.jpg' alt={`${user.id}`} />
                </div>
                <div className='text-sm flex flex-col gap-1'>
                    {!editName ? (
                        <div className='flex items-center gap-1'>
                            <div className='font-semibold hover:cursor-default'>{editNameValue}</div>
                            <div onClick={() => setEditName(true)}>
                                <Icon className='hover:cursor-pointer' path={mdiPencil} size={0.5} />
                            </div>
                        </div>
                    ) : (
                        <div className='flex items-center gap-1'>
                            <input className='outline-none border px-2 rounded-sm' type='text' value={editNameValue} onChange={(e) => setEditNameValue(e.target.value)} />
                            <div onClick={() => updateName(editNameValue)}>
                                <Icon className='hover:cursor-pointer' path={mdiCheck} size={0.8} />
                            </div>
                            <div onClick={() => setEditName(false)}>
                                <Icon className='hover:cursor-pointer' path={mdiClose} size={0.8} />
                            </div>
                        </div>
                    )}
                    {!editEmail ? (
                        <div className='flex items-center gap-1'>
                            <div className='font-semibold'>{editEmailValue}</div>
                            <div onClick={() => setEditEmail(true)}>
                                <Icon className='hover:cursor-pointer' path={mdiPencil} size={0.5} />
                            </div>
                        </div>
                    ) : (
                        <div className='flex items-center gap-1'>
                            <input className='outline-none border px-2 rounded-sm' type='email' value={editEmailValue} onChange={(e) => setEditEmailValue(e.target.value)} />
                            <div onClick={() => updateEmail(editEmailValue)}>
                                <Icon className='hover:cursor-pointer' path={mdiCheck} size={0.8} />
                            </div>
                            <div onClick={() => setEditEmail(false)}>
                                <Icon className='hover:cursor-pointer' path={mdiClose} size={0.8} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div>
                {editPassword ? (
                    <div className='w-fit flex flex-col gap-3'>
                        <div onClick={() => setEditPassword(false)} className='px-3 py-2 border-b-2 w-fit border-b-yellow-500 hover:cursor-default'>Change Password</div>
                        <div className='flex flex-col gap-2'>
                            <input className='outline-none border px-2 rounded-sm' type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Old Password' />
                            <input className='outline-none border px-2 rounded-sm' type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder='New Password' />
                            <input className='outline-none border px-2 rounded-sm' type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder='Confirm New Password' />
                        </div>
                        <div className='flex gap-1'>
                            <div onClick={() => updatePassword({ password, newPassword, confirmPassword })}>
                                <Icon className='hover:cursor-pointer' path={mdiCheck} size={0.8} />
                            </div>
                            <div onClick={() => {
                                setEditPassword(false)
                                setPassword('')
                                setNewPassword('')
                                setConfirmPassword('')
                            }}>
                                <Icon className='hover:cursor-pointer' path={mdiClose} size={0.8} />
                            </div>
                        </div>
                    </div>

                ) : (
                    <div className='flex gap-3'>
                        <button onClick={() => setEditPassword(true)} className='bg-yellow-400 px-3 py-2 rounded-lg hover:scale-105 duration-300 ease-in-out'>Change Password</button>
                        <button onClick={() => logoutUser()} className='bg-red-400 px-3 py-2 rounded-lg hover:scale-105 duration-300 ease-in-out'>Logout</button>
                    </div>
                )}
            </div>
        </div >
    )
}

export default AuthWrapper(Profile)