import Icon from '@mdi/react'
import { mdiAlphabetAurebesh, mdiShopping, mdiStarBox, mdiBookshelf, mdiLogin } from '@mdi/js'

import { NavLink } from 'react-router-dom'
import useBookStore from '../store'
import { useEffect, useState } from 'react'

const Navbar = () => {

    const { wishlist, cart, initializeWishlist, initializeCart } = useBookStore();
    const [jwt, setJwt] = useState('')
    const [ham, setHam] = useState(false)

    useEffect(() => {

        setJwt(localStorage.getItem('jwt'));

        if (jwt) {
            initializeCart();
            initializeWishlist();
        }

    }, [initializeCart, initializeWishlist, jwt])

    const totalItemsInWishlist = wishlist.length
    const totalItemsInCart = cart.reduce((total, book) => total + book.quantity, 0) || 0;

    return (
        <nav className="border-b-2 text-lg px-6 py-6 lg:py-0 font-bold shadow-md bg-white text-gray-700">
            <div className="flex items-center justify-between container mx-auto">
                <NavLink to="/" className='flex items-center gap-3'>
                    <Icon
                        path={mdiAlphabetAurebesh}
                        size={2}
                        horizontal
                        rotate={90}
                        color="white"
                        className='border p-2 rounded-full bg-black'
                    />
                    <div>Traversify</div>
                </NavLink>
                <div className="lg:hidden relative">
                    <div className="flex items-center space-x-5">
                        <div onClick={() => setHam(!ham)} className="space-y-1 cursor-pointer">
                            <div className={`border px-3 ${ham ? 'rotate-45 -mb-1.5' : ''} border-black bg-black duration-100 ease-in-out`}></div>
                            <div className={`border px-3 ${ham ? 'hidden' : ''} border-black duration-100 ease-in-out bg-black`}></div>
                            <div className={`border px-3 ${ham ? '-rotate-45' : ''} border-black duration-100 ease-in-out bg-black`}></div>
                        </div>
                    </div>
                </div>
                {ham && (
                    <div className="absolute lg:hidden top-14 right-6 md:right-10 border bg-white w-48 shadow-md p-4 mt-2 rounded-lg space-y-4 z-10">
                        <NavLink to="/booklist" onClick={() => setHam(false)} className="block px-4 py-2 rounded-lg hover:bg-gray-200">Booklist</NavLink>
                        <NavLink to="/wishlist" onClick={() => setHam(false)} className="block px-4 py-2 rounded-lg hover:bg-gray-200">Wishlist</NavLink>
                        <NavLink to="/cart" onClick={() => setHam(false)} className="block px-4 py-2 rounded-lg hover:bg-gray-200">Cart</NavLink>
                        <NavLink to="/my-profile" onClick={() => setHam(false)} className="block px-4 py-2 rounded-lg hover:bg-gray-200">Profile</NavLink>
                    </div>
                )}
                <div className="lg:flex px-5 py-8 items-center space-x-4 hidden">
                    <div className='flex gap-7 items-center'>
                        <NavLink to="/booklist" className='link'>
                            <Icon
                                path={mdiBookshelf}
                                size={1.5}
                                color="black"
                            />
                            <div className='text-[0.6em]'>Booklist</div>
                        </NavLink>
                        <NavLink to="/wishlist" className='link relative'>
                            <Icon
                                path={mdiStarBox}
                                size={1.5}
                                color="black"
                            />
                            <div className='text-[0.6em]'>Wishlist</div>
                            <span className="absolute top-0 left-5 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                                {totalItemsInWishlist}
                            </span>
                        </NavLink>
                        <NavLink to="/cart" className='relative link'>
                            <Icon
                                path={mdiShopping}
                                size={1.5}
                                color="black"
                            />
                            <div className='text-[0.6em]'>Cart</div>
                            <span className="absolute top-0 left-5 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                                {totalItemsInCart}
                            </span>
                        </NavLink>
                        {jwt ? (
                            <NavLink to="/my-profile" className="link">
                                <img
                                    src="/assets/emmett.jpg"
                                    alt="profilePhoto"
                                    className="w-[35px] rounded-lg border border-dashed border-black"
                                />
                                <div className='text-[0.6em]'>Profile</div>
                            </NavLink>
                        ) : (
                            <NavLink to="/login" className="link">
                                <Icon path={mdiLogin} size={1.5} color="black" />
                                <div className='text-[0.6em]'>Login</div>
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar