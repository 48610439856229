import axios from "axios";

export const apiRequest = async (method, url, data = {}) => {
    try {
        const response = await axios({
            method,
            url,
            data,
            baseURL: 'https://ec2-18-191-240-144.us-east-2.compute.amazonaws.com/api',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error(`Error with ${method} request to ${url}:`, error);
        throw error;
    }
};