import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
    return (
        <div className='w-full text-white relative px-4 mt-10 md:mt-8 lg:mt-5 flex gap-6 items-center justify-center'>
            <img className='rounded-lg' src='/assets/home.jpg' alt='home'></img>
            <div className='flex flex-col absolute items-center gap-3'>
                <div className='text-center text-2xl font-bold'>
                    <div>
                        Welcome to Traversify,
                    </div>
                    <div>
                        an online store for your book needs
                    </div>
                </div>
                <Link to="/booklist">
                    <button className='px-4 py-2 text-black hover:scale-105 duration-300 transition-all bg-yellow-400 rounded-xl'>Browse books</button>
                </Link>
            </div>
        </div>
    )
}


export default Home