import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider
} from "react-router-dom";

import BookList from "./components/booklist/BookList";
import WishList from "./components/WishList";
import Home from "./components/Home";
import Cart from "./components/Cart";
import BookDetails from "./components/booklist/BookDetails";
import NotFound from "./components/NotFound";

import RootLayout from "./layouts/RootLayout";
import BooksLayout from "./layouts/BooksLayout";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.css';
import Login from "./components/Login";
import Signup from "./components/signup/Signup";
import Profile from "./components/Profile";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path="booklist" element={<BooksLayout />}>
          <Route index element={<BookList />} />
          <Route path=":id" element={<BookDetails />} />
        </Route>
        <Route path="wishlist" element={<WishList />} />
        <Route path="cart" element={<Cart />} />
        <Route path="my-profile" element={<Profile />} />
      </Route>
      <Route path="*" element={<NotFound />} />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
    </>
  )
)

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}

export default App;
