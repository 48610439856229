import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiRequest } from "../../axiosHelper";
import { errorToast, successToast } from "../../toast";

const SignupForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [passkey, setPasskey] = useState("");
    const [confPasskey, setConfPasskey] = useState("");
    const navigate = useNavigate();

    const setter = (message) => {
        const notif = document.getElementById("notif");
        notif.innerText = message;
    };

    const reset = () => {
        setName("");
        setEmail("");
        setPasskey("");
        setConfPasskey("");
    };

    const buttonSetter = (text) => {
        const button = document.getElementById("button");
        button.innerText = "ACCOUNT CREATED!";
        setTimeout(() => {
            button.innerText = "CREATE ACCOUNT";
        }, 1500);
    };

    const submit = async (e) => {
        e.preventDefault();

        try {
            if (passkey !== confPasskey) {
                setter(`passwords don't match`);
            } else {
                const user = {
                    name,
                    email,
                    password: passkey,
                };

                const response = await apiRequest('post', '/user/register', user)
                localStorage.setItem('jwt', response.token)

                setter("");
                buttonSetter("ACCOUNT CREATED");
                reset();
                navigate('/')
                successToast('Welcome!')
            }
        } catch (error) {
            errorToast(error.response.data.message)
        }
    };

    return (
        <div>
            <div className="px-10 py-8">
                <div className="w-3/4">
                    <div className="font-semibold text-2xl">Let's do this!</div>
                    <form className="relative" onSubmit={submit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:gap-x-10 lg:gap-y-6 gap-4 mt-7">
                            <div className="font-semibold text-gray-700">
                                <div className="tracking-wider">NAME</div>
                                <input
                                    type="text"
                                    name="name"
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    className="outline-none w-full rounded border-2 px-2 py-1"
                                />
                            </div>
                            <div className="font-semibold text-gray-700">
                                <div className="tracking-wider">EMAIL</div>
                                <input
                                    type="email"
                                    name="email"
                                    required={true}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    className="outline-none w-full rounded border-2 px-2 py-1"
                                />
                            </div>
                            <div className="font-semibold text-gray-700">
                                <div className="tracking-wider">PASSWORD</div>
                                <input
                                    type="password"
                                    name="password"
                                    required={true}
                                    onChange={(e) => setPasskey(e.target.value)}
                                    value={passkey}
                                    className="outline-none rounded w-full border-2 px-2 py-1"
                                />
                                <div className="text-red-500 pl-1 text-sm" id="notif"></div>
                            </div>
                            <div className="font-semibold text-gray-700">
                                <div className="tracking-wider">CONFIRM PASSWORD</div>
                                <input
                                    name="confpasskey"
                                    value={confPasskey}
                                    required={true}
                                    onChange={(e) => setConfPasskey(e.target.value)}
                                    type="password"
                                    className="outline-none rounded w-full border-2 px-2 py-1"
                                />
                            </div>
                        </div>
                        <div className="my-5 absolute flex flex-col items-start">
                            <button
                                id="button"
                                type="submit"
                                className="border outline-none px-7 mt-2 md:px-11 py-4 hover:scale-105 duration-300 ease-out font-bold text-sm text-white shadow-md rounded-xl bg-green-800"
                            >
                                Create Account
                            </button>
                            <div className="my-5 font-bold text-base md:text-xl">
                                Already have an account?{" "}
                                <span className="text-green-800">
                                    <Link className="hover:underline" to="/login">Log in</Link>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignupForm;
