import { create } from 'zustand';
import { successToast, errorToast } from './toast';
import { apiRequest } from './axiosHelper';

const useBookStore = create((set, get) => ({
    wishlist: [],
    cart: [],
    user: {},

    fetchLoggedInUser: async () => {
        try {
            const user = await apiRequest('get', '/user/profile');
            set({ user })
        } catch (error) {
            errorToast(`Error fetching user details: ${error.message || error.response?.data || error}`)
        }
    },

    initializeCart: async () => {
        try {
            const data = await apiRequest('get', '/cart');
            set({ cart: data });
        } catch (error) {
            errorToast(`Error fetching Cart: ${error.message || error.response?.data || error}`)
        }
    },

    initializeWishlist: async () => {
        try {
            const data = await apiRequest('get', '/wishlist');
            set({ wishlist: data });
        } catch (error) {
            errorToast(`Error fetching Wishlist: ${error.message || error.response?.data || error}`)
        }
    },

    increaseQuantity: (id) => set(async (state) => {
        const item = state.cart.find(item => item.book.id === id);
        if (item) {
            try {
                const response = await apiRequest('patch', `/cart/update/${item.book.id}`, { quantity: item.quantity + 1 });
                set((state) => ({
                    cart: state.cart.map(cartItem =>
                        cartItem.book.id === item.book.id ? response : cartItem
                    )
                }));
                successToast("Cart Updated!")
            } catch (error) {
                errorToast(`Error updating cart: ${error.response?.data || error.message}`);
            }
        }
    }),

    decreaseQuantity: (id) => set(async (state) => {
        const item = state.cart.find(item => item.book.id === id);
        if (item) {
            if (item.quantity === 1) {
                await get().removeFromCart(id);
            } else {
                try {
                    const response = await apiRequest('patch', `/cart/update/${item.book.id}`, { quantity: item.quantity - 1 });
                    set((state) => ({
                        cart: state.cart.map(cartItem =>
                            cartItem.book.id === item.book.id ? response : cartItem
                        )
                    }));
                    successToast("Cart Updated!")
                } catch (error) {
                    errorToast(`Error updating cart: ${error.response?.data || error.message}`);
                }
            }
        }
    }),

    addToWishlist: (bookId) => set(async (state) => {
        if (!state.wishlist.some(item => item.id === bookId)) {
            try {
                const response = await apiRequest('post', '/wishlist/add', { bookId });
                set((state) => ({
                    wishlist: [...state.wishlist, response],
                }));
                successToast(`${response.title} added to wishlist!`);
            } catch (error) {
                errorToast(`Could not add to wishlist: ${error.response?.data || error.message}`);
            }
        }
    }),

    removeFromCart: (bookId) => set(async (state) => {
        try {
            await apiRequest('delete', `/cart/${bookId}`);
            const updatedCart = state.cart.filter(item => item.book.id !== bookId);
            set({ cart: updatedCart })
            successToast("Removed from cart!");
        } catch (error) {
            errorToast(`Could not remove from cart: ${error.response?.data || error.message}`);
        }
    }),

    removeFromWishlist: (bookId) => set(async (state) => {
        try {
            await apiRequest('delete', `/wishlist/${bookId}`);
            const updatedWishlist = state.wishlist.filter(item => item.id !== bookId);
            set({ wishlist: updatedWishlist });
            successToast("Removed from wishlist!");
        } catch (error) {
            errorToast(`Could not remove from wishlist: ${error.response?.data || error.message}`);
        }
    }),

    addToCart: (bookId) => set(async (state) => {
        const item = state.cart.find(item => item.book.id === bookId);
        if (item) {
            try {
                const response = await apiRequest('patch', `/cart/update/${item.book.id}`, { quantity: item.quantity + 1 });
                set((state) => ({
                    cart: state.cart.map(cartItem =>
                        cartItem.book.id === item.book.id ? response : cartItem
                    )
                }));
                successToast("Cart Updated!");
            } catch (error) {
                errorToast(`Could not update cart: ${error.response?.data || error.message}`);
            }
        } else {
            try {
                const updatedItem = { bookId, quantity: 1 };
                const response = await apiRequest('post', '/cart/add', updatedItem);
                set((state) => ({ cart: [...state.cart, response] }));
                successToast(`${response.book.title} added to cart!`)
            } catch (error) {
                errorToast(`Could not add to cart: ${error.response?.data || error.message}`);
            }
        }
    }),

}));

export default useBookStore;
