const SignupHead = () => {
    return (
        <div className="bg-gray-100 w-full">
            <div className="font-semibold text-2xl flex flex-col items-start justify-center">
                <div className="px-10 lg:mt-24 my-16">
                    <div className="max-w-[55ch]">
                        Traversify Registration for new users
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignupHead