import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Puff } from 'react-loader-spinner'
import { apiRequest } from '../../axiosHelper'

const BookList = () => {

    const [bookList, setBookList] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [searchTerm, setSearchTerm] = useState('')
    const [minPrice, setMinPrice] = useState('')
    const [maxPrice, setMaxPrice] = useState('')

    useEffect(() => {
        const fetchBookList = async () => {
            try {
                const response = await apiRequest('get', '/booklist')
                setBookList(response)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        fetchBookList()
    }, [])

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
    }

    const handleMinPriceChange = (event) => {
        setMinPrice(event.target.value)
    }

    const handleMaxPriceChange = (event) => {
        setMaxPrice(event.target.value)
    }

    const filteredBooks = bookList.filter((book) => {
        const matchesSearchTerm = book.title.toLowerCase().includes(searchTerm.toLowerCase())
        const matchesMinPrice = minPrice === '' || book.price >= parseFloat(minPrice)
        const matchesMaxPrice = maxPrice === '' || book.price <= parseFloat(maxPrice)
        return matchesSearchTerm && matchesMinPrice && matchesMaxPrice
    })

    if (loading) {
        return (
            <div className='h-screen flex items-center justify-center'>
                <Puff
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        )
    }

    if (error) {
        return <div>Error loading books: {error.message}</div>
    }

    return (
        <div className='mt-3 p-2 flex flex-col items-center gap-5'>
            <h1 className='text-xl font-semibold'>Browse from our collection of books!</h1>
            <div className='flex flex-col lg:flex-row gap-4 mb-4'>
                <input
                    type='text'
                    placeholder='Search by name'
                    value={searchTerm}
                    onChange={handleSearch}
                    className='p-2 border rounded'
                />
                <input
                    type='number'
                    placeholder='Min price'
                    value={minPrice}
                    onChange={handleMinPriceChange}
                    className='p-2 border rounded'
                />
                <input
                    type='number'
                    placeholder='Max price'
                    value={maxPrice}
                    onChange={handleMaxPriceChange}
                    className='p-2 border rounded'
                />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                {filteredBooks.map((book) => (
                    <Link to={book.id.toString()} key={book.id} className='flex gap-2 text-xs flex-col hover:bg-gray-900 hover:text-white transition-all duration-300 items-center p-4 border shadow-md cursor-pointer bg-white'>
                        <img src={book.cover} alt={book.title} className='border border-gray-800 w-24 h-36 object-cover' />
                        <div className='flex flex-col items-center'>
                            <div className='font-semibold'>{book.title}</div>
                            <div className='italic'>${book.price}</div>
                        </div>
                        <div>by {book.authors[0]}</div>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default BookList