import React from 'react'
import { Outlet } from 'react-router-dom'

const BooksLayout = () => {

    return (
        <div className='books-layout'>
            <Outlet />
        </div>
    )
}

export default BooksLayout